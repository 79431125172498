.rodal-mask{
  background: rgba(0, 0, 0, 0.7);
}
.rodal-dialog{
  background: transparent;
  color:$color-dark;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
  box-shadow: none !important;
  height:100% !important;
}

.modalWrapper{
  background: $color-white;
  display: flex;
  height: auto;
  flex-direction: column;
  width:100%;
  box-shadow: 0 0 15px rgba($color-black, .7);

  >*{
    display: flex;
    flex-direction: column;
  }

  > .modalHeader{
    height: 60px;
    padding: 0 25px;
    justify-content: center;
    border-bottom: 1px solid rgba($color-black,.2);
    h3{
      margin:0;
      padding:0;
      color: $color-black;
    }
  }
  > .modalBody{
    flex:1;
    padding: 25px;
  }
  > .modalFooter{
    height: 60px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    padding: 0 25px;
    background: rgba($color-black,.05);
  }
}
