.button,
a.button,
a.button:link{
  display: flex;
  text-transform: lowercase;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  // color:$color-darkgray;
  // background: $color-light;
  // font-family: $fontfamily-secondary;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
  padding:6px 15px;
  border:1px solid transparent;
  border-radius: 0;
  @include _transition;
  max-height: 36px;

  &.fullWidth{
    width: 100%;
  }

  &.lg{
    padding:10px 15px;
  }

  &.xl{
    padding:15px 15px;
    max-height: 46px;
  }

  > svg{
    width:18px;
    height: 18px;
    margin-right: 5px;
  }

  &.blank{
    background: transparent;
    border-color: transparent;
    padding: 0;
  }

  &.accent{
    color:$color-white;
    background: $color-accent;
    border-color: $color-accent;
    &.outline{
      background: transparent;
      color:$color-accent;
    }
  }

  &.white{
    background: $color-white;
    border-color: $color-white;
    color:$color-dark;
    &.outline{
      background: transparent;
      color:$color-white;
    }
  }

  &.dark{
    color:$color-white;
    background: $color-black;
    border-color: $color-black;
    &.outline{
      background: transparent;
      color:$color-black;
    }
  }

  &:hover{
    color:$color-white;
    background: $color-dark;
    text-decoration: none;
    cursor: pointer;
  }

  &:disabled,
  &.disabled{
    opacity: .5;
  }

  &:focus,
  &:active{
    color:$color-white;
    background: $color-black;
      outline: none;
  }

}


button.iconButton,
a.iconButton,
a.iconButton:link{
  display: inline-block;
  border: none;
  background: none;
  padding:5px;
  margin:2px;
  width: 30px;
  height: 30px;
  color:$color-dark;
  >svg{
    width:18px;
    height:18px;
    opacity: .7;
  }
  &:hover{
    cursor: pointer;
    >svg{
      opacity: 1;
    }
  }
  &:focus{
    outline: none;
  }
}


.buttonGroup{
  display: flex;
  .button,
  a.button,
  a.button:link{
    border-radius: 0;
    border-right: none;
    &:first-child{
      border-radius: 4px 0 0 4px;
    }
    &:last-child{
      border-radius: 0 4px 4px 0;
      border-right: 1px solid transparent;
      &.accent{
        border-right: 1px solid $color-accent;
      }
      &.white{
        border-right: 1px solid $color-white;
      }
      &.dark{
        border-right: 1px solid rgba(0,0,0,.4);
      }
    }
  }
}
