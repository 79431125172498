.tableWrapper{
  width:100%;
  position: relative;

  table{
    width:100%;
    a{
      font-size: 14px;
      display: block;
    }
  }

  >*{
    width:100%;
  }

  >.head{
    border-bottom: 2px solid rgba($color-black,1);
    > table{
      tr{
        >th{
          font-weight: 400;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 7px 5px;
          color: $color-black;

          &.clickable{
            cursor: pointer;
          }

          &.active{
            background:rgba($color-black,.05);
            position: relative;

            &:after{
              position: absolute;
              top:7px;
              right:5px;
              font-size: 20px;
            }
            &.up{
              &:after{
                content: "\2191";
              }
            }
            &.down{
              &:after{
                content: "\2193";
              }
            }
          }

          &.left{
            text-align: left;
          }
          &.right{
            text-align: right;
          }

          &:last-child{
            padding-right: 25px;
          }
        }
      }
    }
  }

  >.body{
    > table{
      tr{
        background: transparent;
        @include _transition();
        >td{
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 1px;
          padding: 7px 5px;
          border-bottom: 1px solid rgba($color-black,.1);
          @include _transition();

          &.active{
            background:rgba($color-black,.05);
            color:$color-black;
          }

          &.left{
            text-align: left;
          }
          &.right{
            text-align: right;
          }
        }

        &:hover{
          background: rgba($color-black,.05);
          >td{
            color: $color-black;
          }
        }
      }
    }
  }

  &.basic{
    >.body{
      >table{
        border-top: 1px solid rgba($color-black,.1);
        color:$color-black;
      }
    }
  }

}
