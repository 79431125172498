.pageHead{
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding-bottom: 25px;
  margin-bottom: 25px;

  .title{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon{
      color:$color-accent;
      margin-right: 20px;
    }

    h2{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color:$color-black;
      font-weight: 400;
      margin:0;
    }

    .desc{
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  &.noBottom{
    margin-bottom: 0;
    border-bottom: none;
  }

}
