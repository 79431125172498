.tabs{

  .tabNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div{
      &:first-child{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &:last-child{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button{
          &:nth-child(1){
            background: rgba(0,0,0,.05);
          }
          &:nth-child(2){
            background: rgba(0,0,0,.1);
          }
          &:nth-child(3){
            background: rgba(0,0,0,.15);
          }
          &:nth-child(4){
            background: rgba(0,0,0,.2);
          }
        }
      }
    }
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border:none;
      padding: 15px 15px;
      color: $color-black;
      cursor: pointer;
      &:disabled{
        color: rgba($color-black,.5);
        cursor: auto;
      }
      >svg{
        margin-right: 5px;
      }
      &.completed{
        color:$color-black;
        cursor: pointer;
        >svg{
          color:$color-green;
        }
      }
      &.active{
        background: $color-white !important;
        color:$color-black;
        box-shadow: 0 -6px 12px rgba(0,0,0,.05);
        cursor: pointer;
        >svg{
          color:$color-green;
        }
      }
    }
  }

  .tabContent{
    background: $color-white;
    box-shadow: 0 6px 12px rgba(0,0,0,.05);
    padding: 25px;
  }

}
