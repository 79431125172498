.spin{
  -webkit-animation: spin .7s infinite linear;
          animation: spin .7s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

._loading{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 9;
  @include _transition;

  &.full{
    position: fixed;
    z-index: 9999;
    background: rgba(0,0,0,.5);
  }

  >i{
    display: block;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width:50px;
    height:50px;
    border-radius: 50%;
    border:2px solid transparent;
    border-top:2px solid $color-light;

    &:nth-child(1){
      border-top:2px solid $color-accent;
      -webkit-animation: spin 1.2s infinite linear;
              animation: spin 1.2s infinite linear;
    }

    &:nth-child(2){
      border-top:2px solid rgba($color-accent, .7);
      -webkit-animation: spin 1s infinite linear;
              animation: spin 1s infinite linear;
    }

    &:nth-child(3){
      border-top:2px solid rgba($color-accent, .3);
      -webkit-animation: spin .7s infinite linear;
              animation: spin .7s infinite linear;
    }
  }

  &.hide{
    z-index: -1;
    opacity: 0;
  }
}
