.form-group{
  width:100%;
  padding:5px 0;
  margin-bottom: 10px;
  position: relative;

  .react-datepicker-wrapper{
    width:100%;
  }

  label{
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    opacity: .7;
    letter-spacing: .5px;
  }

  .appendIcon{
    position: absolute;
    bottom:7px;
    right:10px;
    cursor: pointer;
    color: $color-black;
    opacity: .5;
    &:focus,
    &:active,
    &:hover{
      opacity: 1;
    }
  }

  .errorField{
    // position: absolute;
    // top:calc(100% - 8px);
    // left:0;
    // right:0;
    margin-top: -1px;
    padding: 2px 10px;
    font-size: 12px;
    background: rgba($color-red, .2);
    border-top:1px solid $color-red;
    opacity: 1;
    color:$color-black;
    letter-spacing: .5px;
  }
}




.form-control{
  display: block;
  width:100%;
  padding:8px 10px;
  border-top: 1px  solid rgba($color-black,.2);
  border-left: 1px  solid rgba($color-black,.2);
  border-right: 1px  solid rgba($color-black,.2);
  border-bottom: 1px  solid $color-black;
  font-family: $fontfamily-primary;
  font-size: 14px;
  line-height: 18px;
  background: $color-white;
  color:$color-black;
  border-radius: 3px 3px 0px 0px;
  // box-shadow: inset 0px 2px 7px rgba($color-black,.2);


  [class*="-control"]{
    border: none !important;
    min-height: 34px !important;
    border-radius: 3px 3px 0px 0px !important;
    &:hover,
    &:focus,
    &:active{
      border-color: $color-accent;
      outline: none;
    }
  }
  [class*="-indicatorContainer"]{
    padding: 7px !important;
  }


  &:disabled{
    background: rgba(0,0,0,.03);
    color: rgba(0,0,0,.5);
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: .5;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    opacity: .5;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    opacity: .5;
  }
  &:-moz-placeholder { /* Firefox 18- */
    opacity: .5;
  }

  &:focus{
    border-color: $color-accent;
    outline: none;
  }

  &.error{
    border-color: $color-red;
  }
}


.cs1Form{
  .form-control{
    display: block;
    width:100%;
    padding:8px 10px;
    border: 1px solid $color-light;
    font-family: $fontfamily-primary;
    font-size: 14px;
    line-height: 18px;
    background: rgba($color-black, .1);
    color:$color-black;
    border-radius: 0px;
    // box-shadow: inset 0px 2px 7px rgba($color-black,.2);
    @include _transition();

    &:focus{
      background: $color-white;
    }
  }
  >div{
    &:first-child{
      .form-control{
        border-radius: 20px 0 0 20px;
        padding-left: 20px;
      }
    }
    &:last-child{
      .form-control{
        border-radius: 0 20px 20px 0;
        padding-right: 20px;
      }
    }
  }
}


select{
  &.form-control{
    appearance:none;
    -webkit-appearance:none;
    -moz-appearance:none;
    -ms-appearance:none;
    background: rgba($color-white,.1) url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAACqADAAQAAAABAAAABgAAAAD+iFX0AAAAPUlEQVQIHW2MAQoAIAjEzv7/50pDWZmQwd2cSZr7+dj52o5+IM4DRCUpC6E0X1mGbmFBazAEf/DbUxDmZl8vlAoC2qo9XwAAAABJRU5ErkJggg==') no-repeat;
    background-position: calc(100% - 15px) 14px;
    padding:8px 10px;
    border-radius: 3px 3px 0px 0px;
    border-top: 1px  solid rgba($color-black,.2);
    border-left: 1px  solid rgba($color-black,.2);
    border-right: 1px  solid rgba($color-black,.2);
    border-bottom: 1px  solid $color-black;
    // box-shadow: inset 0px 2px 7px rgba($color-black,.2);
  }
}

.switch{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  >div{
    display: block;
    position: relative;
    width:64px;
    height: 32px;
    background: $color-dark;
    margin-right: 10px;
    border-radius: 20px;
    @include _transition;

    > i{
      position: absolute;
      display: block;
      top:4px;
      left:5px;
      width:24px;
      height:24px;
      border-radius: 14px;
      background: $color-white;
      @include _transition;
    }
  }

  label{
    font-size: 14px;
    text-transform: none;
  }

  input{
    position: absolute;
    z-index: 9;
    top:0;
    left:0;
    widtH:100%;
    height:100%;
    opacity: 0;

    &:hover{
      cursor: pointer;
    }

    &:checked + div{
      background: $color-accent;
    }

    &:checked + div> i{
      left:auto;
      left:35px;
    }
  }

  &.small{
    >div{
      width:50px;
      height: 24px;
      >i{
        top:3px;
        left:3px;
        width:18px;
        height:18px;
      }
    }
    input{
      &:checked + div > i {
        left:auto;
        left:28px;
      }
    }
  }
}



::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $color-white;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-white;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $color-white;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $color-white;
}



.react-datepicker{
  background: $color-dark;
  color: $color-light;
  border-color: $color-black;

  .react-datepicker__header{
    background: rgba($color-black,.3);
    color: $color-light;
  }

  .react-datepicker__navigation-icon::before,
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    color: $color-light;
  }
  .react-datepicker__current-month{
    color: $color-white;
  }
  .react-datepicker__day{
    outline: none;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: $color-accent;
    color: $color-black;
    outline: none;
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color:rgba($color-white,.2);
    color:$color-white;
  }
}


.errorCheck{
  position: absolute;
  bottom:2px;
  right:2px;
  background: $color-red;
  color:$color-white;
  padding:4px 10px;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 2px;
}

.successCheck{
  position: absolute;
  bottom:2px;
  right:2px;
  background: $color-green;
  color:$color-white;
  padding:4px 10px;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 2px;
}



.formSearch{
  position: relative;
  >input{
    display: block;
    border: none;
    background: rgba($color-dark,.1);
    color:$color-black;
    padding: 9px;
    line-height: 18px;

    &:focus{
      outline: none;
      background: rgba($color-dark,.15);
    }
  }

  >.stateEmpty,
  >.stateFilled{
    position: absolute;
    top:7px;
    right:5px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-black;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-black;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-black;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-black;
  }
}



.repeaterItem{
  padding: 20px 50px 20px 20px;
  background: -moz-linear-gradient(top,  rgba(0,0,0,0.01) 0%, rgba(0,0,0,0.05) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0.05) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0.01) 0%,rgba(0,0,0,0.05) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03000000', endColorstr='#0d000000',GradientType=0 );
  box-shadow: 0 -3px 6px rgba(0,0,0,.05);
  margin:20px 0;
  border-radius: 4px;
  position: relative;

  > .options{
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    width:30px;
    background: rgba(0,0,0,.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
      margin:0;
      padding: 15px 0;
      background: rgba(0,0,0,.05);
      display:block;
      width:100%;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }

    button{
      margin: 0;
      padding: 15px 4px;
      height: auto;
      max-height: inherit !important;
      >svg{
        margin-right: 0 !important;
      }
    }
  }
}
