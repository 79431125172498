html {
  min-height: 100%;
  display: flex;
}
html,
body {
  margin: 0;
  padding: 0;
}

body{
  background: $color-light;
  font-family: $fontfamily-primary;
  font-size: 16px;
  line-height: 24px;
  flex: 1;
  color:$color-dark;
  padding-top: 70px;
}


#root {
  height: 100%;
}


.container-fluid{
  padding-right: 0;
  padding-left: 0;
}

.relative{
  position: relative;
}


.wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  min-height: 100%;

  .main{
    display: flex;
    justify-content: flex-start;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    margin-left: 0;
    min-height: 100%;
    position: relative;
    flex:1;
    @include _transition();

    &.open{
      margin-left: 200px;
      width:calc(100% - 200px);
    }
  }
}


.login{
  .logo{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width:340px;
    margin:20px auto;
    .icon{
      margin-right: 10px;
    }
    >div{
      &:nth-child(1){
        width: 50%;
        margin-left: 15px;
      }
    }
    .text{
      font-weight: 300;
      color: $color-white;
      opacity: .5;
    }
    img{
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
}
